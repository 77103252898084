import { useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";

import axios from "../../api/axios";
const LOGIN_URL = "/auth/signup";

const Register = () => {

  const navigate = useNavigate();

  const errRef = useRef();
  const [errMsg, setErrMsg] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData(e.target);
      const user = Object.fromEntries(formData);
      await axios.put(LOGIN_URL, user, {
        withCredentials: true,
      });

      navigate("/login", { replace: true });
    } catch (err) {
      if (!err?.response) {
        setErrMsg('No Server Response');
      } else if (err.response?.status === 400) {
        setErrMsg('Missing Username or Password');
      } else if (err.response?.status === 401) {
        setErrMsg('Unauthorized');
      } else {
        setErrMsg('E-Mail Adresse bereits vorhanden');
      }
      console.log("error")
      errRef.current.focus();
    }
  };

  return (
    <>
      <div className="" style={{ backgroundColor: "#557059", height: "100vh" }}>
        <div className="pt-5 d-flex justify-content-center fw-bold fs-3">
          <p style={{ color: "#FEF5EC" }}>Willkommen bei WillEyes Wedding</p>
        </div>
        <div className="container mt-5">
          <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive" style={{ color: "red" }}>{errMsg}</p>
          <form onSubmit={handleSubmit}>

            <div className="d-flex form-group mt-3 align-items-center ">
              <input
                type="text"
                name="partner1"
                className="form-control me-3"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Partner"
                required
              />
              <span style={{ color: "#FEF5EC" }}>und</span>
              <input
                type="text"
                name="partner2"
                className="form-control ms-3"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Partner"
                required
              />
              <i className="p-3 bi bi-envelope-fill icon" style={{ color: "#FEF5EC" }}></i>
            </div>
            <div className="d-flex form-group mt-3 align-items-center ">
              <input
                type="date"
                name="date"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Hochzeitstag"
              />
              <i className="p-3 bi bi-envelope-fill icon" style={{ color: "#FEF5EC" }}></i>
            </div>
            <div className="d-flex form-group mt-3 align-items-center ">
              <input
                type="number"
                name="budget"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Budget"
              />
              <i className="p-3 bi bi-envelope-fill icon" style={{ color: "#FEF5EC" }}></i>
            </div>
            <div className="d-flex form-group mt-3 mail align-items-center ">
              <input
                type="email"
                name="email"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="E-Mail Adresse"
                required
              />
              <i className="p-3 bi bi-envelope-fill icon" style={{ color: "#FEF5EC" }}></i>
            </div>
            <div className="d-flex form-group mt-3 password align-items-center ">
              <input
                type="password"
                name="password"
                className="form-control"
                id="exampleInputPassword1"
                placeholder="Passwort"
                required
              />
              <i className="p-3 bi bi-key-fill icon" style={{ color: "#FEF5EC" }}></i>
            </div>
            <div className="d-grid gap-2">
              <button className="btn btn-primary mt-3" style={{ backgroundColor: "#FEF5EC", color: "#557059", border: "1px solid #FEF5EC" }}>Registrieren</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Register;
