import React, { useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

function CakeCard({ cake }) {
    const [edit, setEdit] = useState(false)
    const axiosPrivate = useAxiosPrivate()
    const queryClient = useQueryClient()
    const editRef = useRef()
    const [timer, setTimer] = useState(null);

    const mutationEdit = useMutation({
        mutationFn: (updatedItem) => {
            return axiosPrivate.put('/cake/edit', updatedItem)
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(['cakes']);
            setEdit(false)
        },
    })

    const mutationDelete = useMutation({
        mutationFn: (deleteItem) => {
            return axiosPrivate.delete('/cake/delete', {
                data: deleteItem
            })
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(['cakes']);
        },
    })

    const handleDelete = () => {
        mutationDelete.mutate({ ids: [cake.id] })
    }

    const handleTextEdit = () => {
        mutationEdit.mutate({ ...cake, name: editRef.current.value })
    }

    const handleDescriptionEdit = (event) => {
        if (timer) {
            clearTimeout(timer);
            setTimer(null);
        }
        setTimer(
            setTimeout(async () => {
                mutationEdit.mutate({ ...cake, description: event.target.value })
            }, 500)
        );
    }

    return (
        <div className='mt-1 px-3 py-2 detail-wrapper d-flex justify-content-between w-100'>
            <div>
                {!edit ?
                    <div className='editRow w-100'>
                        <span className=''><i className="bi bi-person-fill"></i>{cake?.name}</span>
                        <span className='edit' onClick={() => setEdit(true)}>&nbsp;<i className="bi bi-pencil"></i></span>
                        <span className='edit' onClick={handleDelete}>&nbsp;<i className="bi bi-trash"></i></span>
                    </div>
                    :
                    <div><input type='text' ref={editRef} name='name' defaultValue={cake?.name} />
                        <span onClick={handleTextEdit} style={{ cursor: "pointer" }}>&nbsp;<i className="bi bi-floppy-fill"></i></span>
                        <span onClick={() => setEdit(false)} style={{ cursor: "pointer" }}>&nbsp;<i className="bi bi-x"></i></span>
                    </div>
                }

                <input type='text' name='description' placeholder='Beschreibung' defaultValue={cake?.description} onChange={handleDescriptionEdit} />
            </div>
        </div>
    )
}

export default CakeCard