import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import DetailNavBar from '../components/DetailNavBar';
import Item from '../components/Schedule/Item';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

function Schedule() {
  const axiosPrivate = useAxiosPrivate()
  const queryClient = useQueryClient()
  const fetchData = async () => {
    const res = await axiosPrivate.get(`/scheduleItem/get`);
    return res.data;
  };

  const { data } = useQuery({
    queryKey: ['scheduleItems'],
    queryFn: () => fetchData(),
    keepPreviousData: true,
  });

  const mutationPost = useMutation({
    mutationFn: (data) => {
      return axiosPrivate.post('/scheduleItem/create', data)
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(['scheduleItems']);
    },
  })

  const handlePost = async (e) => {
    mutationPost.mutate({})
  }

  return (
    <div className='container detail p-3'>
      <DetailNavBar backTo={"seating"} />
      {/* Datum */}
      <div className='mt-5 p-3'>
        {/* Partner */}
        {data?.map(item => (
          <Item key={item.id} data={item} />
        ))}

        <div className='row detail-wrapper p-2' style={{ borderTopRightRadius: "0px", borderTopLeftRadius: "0px" }}>

          <div className='d-flex gap-2' onClick={handlePost} style={{ cursor: "pointer" }} >
            <i className="bi bi-plus-square"></i><span >Hinzufügen</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Schedule