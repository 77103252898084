export const formatDate = (date) => {
    var tempDate = new Date(date);
    var newDate = [('0' + tempDate.getDate()).slice(-2), ('0' + (tempDate.getMonth() + 1)).slice(-2), tempDate.getFullYear()].join('.');

    return newDate
}

export const formatDateInput = (date) => {
    var tempDate = new Date(date);
    var newDate = [tempDate.getFullYear(), ('0' + (tempDate.getMonth() + 1)).slice(-2), ('0' + tempDate.getDate()).slice(-2)].join('-');

    return newDate
}

export const getDayName = () => {
    var date = new Date();
    return date.toLocaleDateString("de-DE", { weekday: 'long' });
}

export const getCurrentWeek = () => {
    var currentDate = new Date();
    var startDate = new Date(currentDate.getFullYear(), 0, 1);
    let days = Math.floor((currentDate - startDate) /
        (24 * 60 * 60 * 1000));

    return Math.ceil(days / 7);
}