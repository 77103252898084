import React, { useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { formatDateInput } from '../../util/DateHelper';

function Item({ data }) {
    const axiosPrivate = useAxiosPrivate()
    const queryClient = useQueryClient()
    const [edit, setEdit] = useState(false)
    const editRef = useRef()
    const [timer, setTimer] = useState(null);
    const mutationEdit = useMutation({
        mutationFn: (updatedItem) => {
            return axiosPrivate.put('/scheduleItem/edit', updatedItem)
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(['scheduleItems']);
            setEdit(false)
        },
    })

    const mutationDelete = useMutation({
        mutationFn: (deleteItem) => {
            return axiosPrivate.delete('/scheduleItem/delete', {
                data: deleteItem
            })
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(['scheduleItems']);
        },
    })

    const handleTextEdit = (event) => {
        if (timer) {
            clearTimeout(timer);
            setTimer(null);
        }
        setTimer(
            setTimeout(async () => {
                mutationEdit.mutate({ ...data, [event.target.name]: event.target.value })
            }, 500)
        );
    }

    const handleDelete = () => {
        mutationDelete.mutate({ ids: [data.id] })
    }

    return (
        <div className='row p-2 foto-schedule'>
            <div className='d-flex justify-content-between'>
                <div className='d-grid'>
                    <div className='d-flex'>
                        {!edit ?
                            <div className='editRow w-100'>
                                <span className=''>{data?.title}</span>
                                <span className='edit' onClick={() => setEdit(true)}>&nbsp;<i className="bi bi-pencil"></i></span>
                            </div>
                            :
                            <div><input type='text' ref={editRef} name='name' defaultValue={data?.title} />
                                <span onClick={handleTextEdit} style={{ cursor: "pointer" }}>&nbsp;<i className="bi bi-floppy-fill"></i></span>
                                <span onClick={() => setEdit(false)} style={{ cursor: "pointer" }}>&nbsp;<i className="bi bi-x"></i></span>
                            </div>
                        }
                    </div>
                    <span className='subtext'>Empfehlung 3-4h</span>
                </div>
                <span style={{ cursor: "pointer", color: "#F67979" }} onClick={handleDelete}><i className="bi bi-trash"></i></span>
            </div>
            <div className='d-flex p-2 align-items-center'>
                <input type='date' name='date' defaultValue={data ? formatDateInput(data?.date) : ""} onChange={handleTextEdit} />
                <span className='mx-2 subtext'>Von</span>
                <input type='time' name='timeFrom' defaultValue={data?.timeFrom} onChange={handleTextEdit} />
                <span className='mx-2 subtext'>Bis</span>
                <input type='time' name='timeUntil' defaultValue={data?.timeUntil} onChange={handleTextEdit} />
            </div>
        </div>
    )
}

export default Item