import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import Planning from "../components/Planning";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

function Dashboard() {
  const axiosPrivate = useAxiosPrivate()
  const queryClient = useQueryClient()

  const [timer, setTimer] = useState(null);
  const fetchData = async () => {
    const res = await axiosPrivate.get(`/user/getById`);
    return res.data;
  };

  const { data } = useQuery({
    queryKey: ['user'],
    queryFn: () => fetchData(),
    keepPreviousData: true,
  });
  return (
    <div className="container">
      <div className="row mt-3">
        <div className="col-12 col-md-12">
          <Planning data={data} />
        </div>
        {/* <div className="col-12 col-md-6">
          <Timetable />
        </div> */}
      </div>
    </div>
  );
}

export default Dashboard