import React, { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import DetailNavBar from '../components/DetailNavBar'
import useAxiosPrivate from '../hooks/useAxiosPrivate'

function Decoration() {
  const axiosPrivate = useAxiosPrivate()
  const queryClient = useQueryClient()

  const [timer, setTimer] = useState(null);
  const fetchData = async () => {
    const res = await axiosPrivate.get(`/decoration/get`);
    return res.data;
  };

  const { data } = useQuery({
    queryKey: ['decoration'],
    queryFn: () => fetchData(),
    keepPreviousData: true,
  });

  const mutationEdit = useMutation({
    mutationFn: (updatedItem) => {
      return axiosPrivate.put('/decoration/edit', updatedItem)
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(['decoration']);
      queryClient.invalidateQueries(['user']);
    },
  })

  const handleTextEdit = (event) => {
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(
      setTimeout(async () => {
        mutationEdit.mutate({ ...data, [event.target.name]: event.target.value })
      }, 500)
    );
  }

  return (
    <div className='container detail p-3'>
      <DetailNavBar backTo={"catering"} goTo={"foto"} />

      {/* Datum */}
      {/* Partner */}
      <div className='row p-2'>
        <span className='subtext p-2'>Partner Dekoration</span>
        <div className='row'>
          <input type='text' name='partner' placeholder='WillEyes Wedding' defaultValue={data?.partner} onChange={handleTextEdit} />
        </div>
      </div>
      <div className='row p-2 mt-1'>
        <span className='subtext p-2'>Wann soll dekoriert werden?</span>
        <div className='d-flex p-2 align-items-center'>
          <span className='me-2 subtext'>Von</span>
          <input type='time' name='timeFrom' defaultValue={data?.timeFrom} onChange={handleTextEdit} />
          <span className='mx-2 subtext'>Bis</span>
          <input type='time' name='timeUntil' defaultValue={data?.timeUntil} onChange={handleTextEdit} />
        </div>
      </div>
      <div className='row p-2'>
        <span className='subtext p-2'>Preis</span>
        <div className='row'>
          <input type='number' name='price' placeholder='Preis' defaultValue={data?.price} onChange={handleTextEdit} />
        </div>
      </div>
      <div className='row p-2'>
        <span className='subtext p-2'>Notizen</span>
        <div className='row'>
          <textarea name='notes' placeholder='Notizen' defaultValue={data?.price} onChange={handleTextEdit} />
        </div>
      </div>
    </div>
  )
}

export default Decoration