import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import DetailNavBar from '../components/DetailNavBar';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { formatDateInput } from '../util/DateHelper';

function Dress() {
  const axiosPrivate = useAxiosPrivate()
  const queryClient = useQueryClient()

  const [timer, setTimer] = useState(null);
  const fetchData = async () => {
    const res = await axiosPrivate.get(`/dress/get`);
    return res.data;
  };

  const { data, isLoading } = useQuery({
    queryKey: ['dress'],
    queryFn: () => fetchData(),
    keepPreviousData: true,
  });

  const mutationEdit = useMutation({
    mutationFn: (updatedItem) => {
      return axiosPrivate.put('/dress/edit', updatedItem)
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(['dress']);
      queryClient.invalidateQueries(['user']);
    },
  })

  const handleSwitch = (event) => {
    const input = { ...data, [event.target.name]: event.target.checked }
    mutationEdit.mutate(input)
  }

  const handleTextEdit = (event) => {
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(
      setTimeout(async () => {
        mutationEdit.mutate({ ...data, [event.target.name]: event.target.value })
      }, 500)
    );
  }

  return (
    <div className='container detail p-3'>
      <DetailNavBar backTo={"music"} goTo={"moderator"} />

      {/* Partner */}
      <div className='mt-5 p-2'>
        <span className='subtext p-2'>Partner Catering</span>
        <div className='row mt-3'>
          <input type='text' name='partner' placeholder='Partner' defaultValue={data?.partner} onChange={handleTextEdit} />
        </div>
      </div>

      {/* Datum */}
      <div className='mt-5 detail-wrapper p-3'>
        <span className='subtext p-3'>Anprobe</span>
        <div className='row mt-3'>
          <div className='col-6'>
            <input type='date' name='date' defaultValue={!isLoading ? formatDateInput(data?.date) : ""} onChange={handleTextEdit} />
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-12 d-flex justify-content-between'>
            Abholung
            <div className="form-check form-switch">
              <input className="form-check-input" name='pickUp' type="checkbox" role="switch" id="flexSwitchCheckDefault" defaultChecked={data?.pickUp} onClick={handleSwitch} />
            </div>
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-12 d-flex justify-content-between'>
            Lieferung
            <div className="form-check form-switch">
              <input className="form-check-input" name='delivery' type="checkbox" role="switch" id="flexSwitchCheckDefault" defaultChecked={data?.delivery} onClick={handleSwitch} />
            </div>
          </div>
        </div>
        <div className='row mt-3 p-3'>
          <span className='subtext p-2'>Preis</span>
          <input type='number' name='price' placeholder='Preis' defaultValue={data?.price} onChange={handleTextEdit} />
        </div>
      </div>

      {/* Datum */}
      <div className='mt-5 detail-wrapper p-3'>
        <span className='subtext p-3'>Dresscode</span>
        <div className='row mt-3'>
          <div className='col-12 d-flex justify-content-between'>
            Dresscode Brautjungfern
            <div className="form-check form-switch">
              <input className="form-check-input" name='dressCodeWomen' type="checkbox" role="switch" id="flexSwitchCheckDefault" defaultChecked={data?.dressCodeWomen} onClick={handleSwitch} />
            </div>
          </div>
        </div>
        <div className='row mt-3 px-3'>
          <input type='text' name='dressCodeWomenDescription' placeholder='Dresscode beschreiben' defaultValue={data?.dressCodeWomenDescription} disabled={!data?.dressCodeWomen} onChange={handleTextEdit} />
        </div>
        <div className='row mt-3'>
          <div className='col-12 d-flex justify-content-between'>
            Dresscode Männer
            <div className="form-check form-switch">
              <input className="form-check-input" name='dressCodeMen' type="checkbox" role="switch" id="flexSwitchCheckDefault" defaultChecked={data?.dressCodeMen} onClick={handleSwitch} />
            </div>
          </div>
        </div>
        <div className='row mt-3 px-3'>

          <input type='text' name='dressCodeMenDescription' placeholder='Dresscode beschreiben' defaultValue={data?.dressCodeMenDescription} disabled={!data?.dressCodeMen} onChange={handleTextEdit} />
        </div>
      </div>
    </div>
  )
}

export default Dress