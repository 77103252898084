import React from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import DetailNavBar from '../components/DetailNavBar'
import Group from '../components/GuestList/Group'
import Person from '../components/GuestList/Person'
import useAxiosPrivate from '../hooks/useAxiosPrivate'

function GuestList() {
  const axiosPrivate = useAxiosPrivate()
  const queryClient = useQueryClient()

  const fetchPersons = async () => {
    const res = await axiosPrivate.get(`/person/get`);
    return res.data;
  };

  const { data: personData } = useQuery({
    queryKey: ['guestsPersons'],
    queryFn: () => fetchPersons(),
    keepPreviousData: true,
  });
  const fetchGroups = async () => {
    const res = await axiosPrivate.get(`/group/get`);
    return res.data;
  };

  const { data: groupData } = useQuery({
    queryKey: ['guestsGroups'],
    queryFn: () => fetchGroups(),
    keepPreviousData: true,
  });

  const mutationPostGroup = useMutation({
    mutationFn: (data) => {
      return axiosPrivate.post('/group/create', data)
    },
    onSuccess: (data, variables, context) => {
      // queryClient.invalidateQueries(['guestsPersons']);
      queryClient.invalidateQueries(['guestsGroups']);
    },
  })

  const handlePostGroup = async (e) => {
    mutationPostGroup.mutate({})
  }

  const mutationPostPerson = useMutation({
    mutationFn: (data) => {
      return axiosPrivate.post('/person/create', data)
    },
    onSuccess: (data, variables, context) => {
      // queryClient.invalidateQueries(['guestsPersons']);
      queryClient.invalidateQueries(['guestsPersons']);
    },
  })

  const handlePostPerson = async (e) => {
    mutationPostPerson.mutate({})
  }

  return (
    <div className='container detail p-3'>
      <DetailNavBar goTo={"location"} />
      {/* <div className='d-flex justify-content-between mt-5 p-0'>
        <input className='custom-search' type='search' placeholder='Suche' />
        <div className='btn' style={{ backgroundColor: "#637E67", borderRadius: "10px", color: "#FBEDE0" }}><i className="bi bi-list"></i></div>
      </div> */}

      {/* <Person person={{ name: "Aragorn", status: "accepted" }} />
      <Person person={{ name: "Legolas Vater", status: "declined" }} /> */}
      {personData?.map(person => (
        <Person key={person.id} person={person} />
      ))}

      {groupData?.map(group => (
        <Group key={group.id} group={group} />
      ))}

      {/* <Group group={{
        name: "Die Coolen",
        persons: [
          { name: "Rene", status: "pending" },
          { name: "Nina", status: "pending" }
        ]
      }} /> */}

      <div className='d-flex justify-content-between mt-5'>
        <div className='detail-wrapper px-3 py-1' onClick={handlePostPerson} style={{ cursor: "pointer" }}><i className="bi bi-person-add"></i> + Neue Person</div>
        <div className='detail-wrapper px-3 py-1' onClick={handlePostGroup} style={{ cursor: "pointer" }}><i className="bi bi-people-fill"></i> + Neue Gruppe</div>
      </div>
    </div>
  )
}

export default GuestList