import { Route, Routes } from "react-router-dom";

import { QueryClient, QueryClientProvider } from 'react-query';
import Activate from "./components/Auth/Activate";
import ForgotPassword from "./components/Auth/ForgotPassword";
import Login from "./components/Auth/Login";
import Register from "./components/Auth/Register";
import SetNewPassword from "./components/Auth/SetNewPassword";
import Layout from "./components/Layout";
import Missing from "./components/Missing";
import RequireAuth from "./components/RequireAuth";
import "./i18n";
import Accommodation from "./pages/Accommodation";
import Admin from "./pages/Admin";
import Cake from "./pages/Cake";
import Car from "./pages/Car";
import Catering from "./pages/Catering";
import Dashboard from "./pages/Dashboard";
import Decoration from "./pages/Decoration";
import Dress from "./pages/Dress";
import Foto from "./pages/Foto";
import GuestList from "./pages/GuestList";
import Invitation from "./pages/Invitation";
import Location from "./pages/Location";
import Moderator from "./pages/Moderator";
import Music from "./pages/Music";
import Schedule from "./pages/Schedule";
import Tables from "./pages/Tables";

const queryClient = new QueryClient()
function App() {
  return (
    <QueryClientProvider client={queryClient} contextSharing={true}>
      <Routes>
        {/* public routes */}
        <Route path="*" element={<Missing />} />

        <Route path="login" element={<Login />} />


        <Route path="forgotPassword" element={<ForgotPassword />} />
        <Route path="resetPassword" element={<SetNewPassword />} />
        <Route path="activate" element={<Activate />} />
        <Route path="register" element={<Register />} />
        <Route element={<RequireAuth allowedRoles={["user", "admin"]} />}>
          <Route path="/" element={<Layout />}>
            <Route path="" element={<Dashboard />} />
            <Route path="accommodation" element={<Accommodation />} />
            <Route path="guestlist" element={<GuestList />} />
            <Route path="cake" element={<Cake />} />
            <Route path="car" element={<Car />} />
            <Route path="catering" element={<Catering />} />
            <Route path="invitation" element={<Invitation />} />
            <Route path="dress" element={<Dress />} />
            <Route path="foto" element={<Foto />} />
            <Route path="location" element={<Location />} />
            <Route path="decoration" element={<Decoration />} />
            <Route path="moderator" element={<Moderator />} />
            <Route path="music" element={<Music />} />
            <Route path="schedule" element={<Schedule />} />
            <Route path="seating" element={<Tables />} />

            <Route element={<RequireAuth allowedRoles={["admin"]} />}>
              <Route path="admin" element={<Admin />} />
            </Route>
          </Route>
        </Route>
      </Routes>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  );
}

export default App;
