import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import DetailNavBar from '../components/DetailNavBar';
import useAxiosPrivate from '../hooks/useAxiosPrivate';


function Catering() {
  const axiosPrivate = useAxiosPrivate()
  const queryClient = useQueryClient()

  const [timer, setTimer] = useState(null);
  const fetchData = async () => {
    const res = await axiosPrivate.get(`/catering/get`);
    return res.data;
  };

  const { data } = useQuery({
    queryKey: ['catering'],
    queryFn: () => fetchData(),
    keepPreviousData: true,
  });

  const mutationEdit = useMutation({
    mutationFn: (updatedItem) => {
      return axiosPrivate.put('/catering/edit', updatedItem)
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(['catering']);
      queryClient.invalidateQueries(['user']);
    },
  })

  const handleSwitch = (event) => {
    const input = { ...data, [event.target.name]: event.target.checked }
    mutationEdit.mutate(input)
  }

  const handleTextEdit = (event) => {
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(
      setTimeout(async () => {
        mutationEdit.mutate({ ...data, [event.target.name]: event.target.value })
      }, 500)
    );
  }

  return (
    <div className='container detail p-3'>
      <DetailNavBar backTo={"location"} goTo={"decoration"} />
      {/* Partner */}
      <div className='mt-5 detail-wrapper p-3'>
        <span className='text'>Partner Catering</span>
        <div className='row mt-3'>
          <input type='text' placeholder='Partner' name='partner' onChange={handleTextEdit} defaultValue={data?.partner} />
        </div>
        <span>Gesamtpreis: {data?.priceFood + data?.priceDrinks + data?.priceExtras}€</span>
      </div>

      {/* Essen */}
      <div className='mt-5 detail-wrapper p-3'>
        <div className='row gy-3'>
          <span className='text'>Was für Essen wird es geben?</span>
          <div className='col-12 d-flex justify-content-between'>
            Vegan
            <div className="form-check form-switch">
              <input className="form-check-input" type="checkbox" name="vegan" role="switch" id="flexSwitchCheckDefault" onClick={handleSwitch} defaultChecked={data?.vegan} />
            </div>
          </div>
          <div className='col-12 d-flex justify-content-between'>
            Glutenfrei
            <div className="form-check form-switch">
              <input className="form-check-input" type="checkbox" name="glutenfree" role="switch" id="flexSwitchCheckDefault" onClick={handleSwitch} defaultChecked={data?.glutenfree} />
            </div>
          </div>
          <div className='col-12 d-flex justify-content-between'>
            Halal
            <div className="form-check form-switch">
              <input className="form-check-input" type="checkbox" name="halal" role="switch" id="flexSwitchCheckDefault" onClick={handleSwitch} defaultChecked={data?.halal} />
            </div>
          </div>
        </div>
        <div className='row mt-3'>
          <span className='subtext p-2'>Preis</span>
          <input type='number' placeholder='Preis' name='priceFood' defaultValue={data?.priceFood} onChange={handleTextEdit} />
        </div>
      </div>

      {/* Trinken */}
      <div className='mt-5 detail-wrapper p-3'>
        <div className='row gy-3'>
          <span className='text'>Trinken</span>
          <div className='col-12 d-flex justify-content-between'>
            Getränkepauschale nach Zeit
            <div className="form-check form-switch">
              <input className="form-check-input" type="checkbox" name='drinksByTime' role="switch" id="flexSwitchCheckDefault" onClick={handleSwitch} defaultChecked={data?.drinksByTime} />
            </div>
          </div>
          <div className='col-12 d-flex justify-content-between'>
            Getränkepauschale Openend
            <div className="form-check form-switch">
              <input className="form-check-input" type="checkbox" name='drinksOpenEnd' role="switch" id="flexSwitchCheckDefault" onClick={handleSwitch} defaultChecked={data?.drinksOpenEnd} />
            </div>
          </div>

        </div>
        <div className='row mt-3'>
          <input type='number' placeholder='Preis' name='priceDrinks' defaultValue={data?.priceDrinks} onChange={handleTextEdit} />
        </div>
      </div>

      {/* Zusätze */}
      <div className='mt-5 detail-wrapper p-3'>
        <div className='row gy-3'>
          <span className='text'>Zusätze</span>
          <div className='col-12 d-flex justify-content-between'>
            Cocktailbar
            <div className="form-check form-switch">
              <input className="form-check-input" type="checkbox" name='cocktailbar' role="switch" id="flexSwitchCheckDefault" onClick={handleSwitch} defaultChecked={data?.cocktailbar} />
            </div>
          </div>

        </div>
        <div className='row mt-3'>
          <input type='number' placeholder='Preis' name='priceExtras' defaultValue={data?.priceExtras} onChange={handleTextEdit} />
          <div className='d-flex p-2 align-items-center'>
            <span className='mx-2 subtext'>Von</span>
            <input type='time' name='timeFrom' defaultValue={data?.timeFrom} onChange={handleTextEdit} />
            <span className='mx-2 subtext'>Bis</span>
            <input type='time' name='timeUntil' defaultValue={data?.timeUntil} onChange={handleTextEdit} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Catering