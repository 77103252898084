import { Outlet } from "react-router-dom";
import NavbarTop from "./NavbarTop";
import Overview from "./Overview";

const Layout = () => {
  return (
    <>
      <div className="">
        <NavbarTop />
      </div>
      <div className="wrapper">
        <div className="main">
          <main className="content px-3 py-2">
            <div className="container">
              <Overview />
            </div>
            <div className="mt-5">
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default Layout;
