import React, { useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

function Person({ person, inGroup = false }) {
    const [edit, setEdit] = useState(false)
    const axiosPrivate = useAxiosPrivate()
    const queryClient = useQueryClient()
    const editRef = useRef()

    const mutationPost = useMutation({
        mutationFn: (data) => {
            return axiosPrivate.post('/person/remove', data)
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(['guestsPersons']);
            queryClient.invalidateQueries(['guestsGroups']);
        },
    })

    const mutationEdit = useMutation({
        mutationFn: (updatedItem) => {
            return axiosPrivate.put('/person/edit', updatedItem)
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(['guestsPersons']);
            queryClient.invalidateQueries(['guestsGroups']);
            setEdit(false)
        },
    })

    const mutationDelete = useMutation({
        mutationFn: (deleteItem) => {
            return axiosPrivate.delete('/person/delete', {
                data: deleteItem
            })
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(['guestsPersons']);
            queryClient.invalidateQueries(['guestsGroups']);
        },
    })

    const handleDelete = () => {
        mutationDelete.mutate({ ids: [person.id] })
    }

    const handleRemove = () => {
        mutationPost.mutate({ id: person.id })
    }

    const handleTextEdit = () => {
        mutationEdit.mutate({ ...person, name: editRef.current.value })
    }

    const handleStatus = (status) => {
        const input = { ...person, status: status }
        mutationEdit.mutate(input)
    }

    return (
        <div className='mt-1 px-3 py-2 detail-wrapper d-flex justify-content-between w-100'>
            {!edit ?
                <div className='editRow w-100'>
                    <span className=''><i className="bi bi-person-fill"></i>{person?.name}</span>
                    <span className='edit' onClick={() => setEdit(true)}>&nbsp;<i className="bi bi-pencil"></i></span>
                    <span className='edit' onClick={handleDelete}>&nbsp;<i className="bi bi-trash"></i></span>
                    {inGroup ?
                        <span className='edit' onClick={handleRemove}>&nbsp;<i className="bi bi-person-dash-fill"></i></span>
                        :
                        <></>
                    }
                </div>
                :
                <div><input type='text' ref={editRef} name='name' defaultValue={person?.name} />
                    <span onClick={handleTextEdit} style={{ cursor: "pointer" }}>&nbsp;<i className="bi bi-floppy-fill"></i></span>
                    <span onClick={() => setEdit(false)} style={{ cursor: "pointer" }}>&nbsp;<i className="bi bi-x"></i></span>
                </div>
            }
            {/* <div><i className="bi bi-person-fill"></i>{person?.name}</div> */}
            <div className="d-flex" style={{ border: "1px solid #FDF4ED" }}>
                <div className='col-4 text-center align-middle' onClick={() => handleStatus("accepted")} style={{ backgroundColor: person?.status === "accepted" ? "#38A02F" : "transparent", width: "39px", height: "100%" }}><i className="bi bi-check-lg"></i></div>
                <div className='col-4 text-center align-middle' onClick={() => handleStatus("pending")} style={{ backgroundColor: person?.status === "pending" ? "#AFA49D" : "transparent", width: "39px", height: "100%" }}><i className="bi bi-dash-lg"></i></div>
                <div className='col-4 text-center align-middle' onClick={() => handleStatus("declined")} style={{ backgroundColor: person?.status === "declined" ? "#B85656" : "transparent", width: "39px", height: "100%" }}><i className="bi bi-x-lg"></i></div>
            </div>
        </div>
    )
}

export default Person