import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import CakeCard from '../components/CakeCard';
import DetailNavBar from '../components/DetailNavBar';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

function Cake() {
  const axiosPrivate = useAxiosPrivate()
  const queryClient = useQueryClient()
  const [timer, setTimer] = useState(null);

  const fetchData = async () => {
    const res = await axiosPrivate.get(`/cake/getData`);
    console.log(res.data)
    return res.data;
  };

  const { data } = useQuery({
    queryKey: ['cake'],
    queryFn: () => fetchData(),
    keepPreviousData: true,
  });

  const fetchCakes = async () => {
    const res = await axiosPrivate.get(`/cake/get`);
    return res.data;
  };

  const { data: cakes } = useQuery({
    queryKey: ['cakes'],
    queryFn: () => fetchCakes(),
    keepPreviousData: true,
  });

  const mutationPost = useMutation({
    mutationFn: (data) => {
      return axiosPrivate.post('/cake/create', data)
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(['cakes']);
    },
  })

  const mutationEdit = useMutation({
    mutationFn: (updatedItem) => {
      return axiosPrivate.put('/cake/editData', updatedItem)
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(['cake']);
    },
  })

  const handlePost = async (e) => {
    mutationPost.mutate({})
  }

  const handleTextEdit = (event) => {
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(
      setTimeout(async () => {
        mutationEdit.mutate({ ...data, [event.target.name]: event.target.value })
      }, 500)
    );
  }

  return (
    <div className='container detail p-3'>

      <DetailNavBar backTo={"foto"} goTo={"invitation"} />
      {/* Partner */}
      <div className='mt-3 detail-wrapper p-3 pt-0'>
        <div className='row mt-3'>
          <span className='subtext p-0 ps-3'>Partner Torte</span>
        </div>
        <div className='row mt-2 ps-3'>
          <input type='text' name="partner" placeholder='Partner' defaultValue={data?.partner} onChange={handleTextEdit} />
        </div>
      </div>
      {/* Torten */}
      <div className='mt-5 detail-wrapper p-3'>
        <div className='row mt-1 px-3'>
          <div className='p-0 d-flex justify-content-between gap-3'>
            <div>Torten</div>
            <div className='pointer' onClick={handlePost}> + Hinzufügen</div>
          </div>
          {cakes?.map(cake => (
            <CakeCard cake={cake} key={cake.id} />
          ))}
        </div>
      </div>
      {/* <div className='row my-3 ps-3'>
        <input type='text' placeholder='Beschreibung der Torte' />
      </div>
      <div className='row my-3 ps-3'>
        <input type='text' placeholder='Beschreibung der Torte' />
      </div> */}

      {/* Preis */}
      <div className='mt-5 detail-wrapper p-3'>
        <div className='row mt-1 ps-3'>
          <span className='subtext p-2'>Preis</span>
          <input type='number' name='price' placeholder='Preis' defaultValue={data?.price} onChange={handleTextEdit} />
        </div>
      </div>
    </div>
  )
}

export default Cake;