import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

function TablePerson({ person, tableId, add }) {
    const axiosPrivate = useAxiosPrivate()
    const queryClient = useQueryClient()

    const mutationPost = useMutation({
        mutationFn: (data) => {
            return axiosPrivate.post('/person/removeFromTable', data)
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(['seatingTables']);
            queryClient.invalidateQueries(['seatingPersons']);
        },
    })

    const mutationPostAdd = useMutation({
        mutationFn: (data) => {
            return axiosPrivate.post('/person/addToTable', data)
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(['seatingTables']);
            queryClient.invalidateQueries(['seatingPersons']);
        },
    })

    const handleRemove = () => {
        mutationPost.mutate({ id: person.id })
    }

    const handleAdd = () => {
        mutationPostAdd.mutate({ id: person.id, tableId: tableId })
    }

    return (
        <div className='mt-1 px-3 py-2 detail-wrapper d-flex justify-content-between w-100'>
            {add ? <div onClick={handleAdd} className='editRow w-100'>
                <span className='' >&nbsp;<i className="bi bi-person-plus-fill"></i></span>
                <span className=''>&nbsp;{person?.name}</span>
            </div> :
                <div className='editRow w-100'>
                    <span className=''>{person?.name}</span>
                    <span className='edit' onClick={handleRemove}>&nbsp;<i className="bi bi-person-dash-fill"></i></span>
                </div>}
        </div>
    )
}

export default TablePerson