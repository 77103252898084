import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import DetailNavBar from '../components/DetailNavBar';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

function Location() {
  const axiosPrivate = useAxiosPrivate()
  const queryClient = useQueryClient()

  const [timer, setTimer] = useState(null);
  const fetchData = async () => {
    const res = await axiosPrivate.get(`/location/get`);
    return res.data;
  };

  const { data } = useQuery({
    queryKey: ['location'],
    queryFn: () => fetchData(),
    keepPreviousData: true,
  });

  const mutationEdit = useMutation({
    mutationFn: (updatedItem) => {
      return axiosPrivate.put('/location/edit', updatedItem)
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(['location']);
      queryClient.invalidateQueries(['user']);
    },
  })

  const handleSwitch = (event) => {
    const input = { ...data, [event.target.name]: event.target.checked }
    mutationEdit.mutate(input)
  }

  const handleTextEdit = (event) => {
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(
      setTimeout(async () => {
        mutationEdit.mutate({ ...data, [event.target.name]: event.target.value })
      }, 500)
    );
  }

  return (
    <div className='container detail p-3'>
      <DetailNavBar backTo={"guestlist"} goTo={"catering"} />
      {/* Locations */}
      <div className='row p-2'>
        <span className='subtext'>Getting Ready</span>
        <div className='row mb-2'>
          <input type='text' placeholder='Getting Ready' name='gettingReady' onChange={handleTextEdit} defaultValue={data?.gettingReady} />
        </div>
        <span className='subtext'>Zeremonie</span>
        <div className='row mb-2'>
          <input type='text' placeholder='Zeremonie' name='ceremony' onChange={handleTextEdit} defaultValue={data?.ceremony} />
        </div>
        <span className='subtext'>Feier</span>
        <div className='row'>
          <input type='text' placeholder='Feier' name='party' onChange={handleTextEdit} defaultValue={data?.party} />
        </div>
      </div>

      {/* Inklusive */}
      <div className='mt-5 detail-wrapper p-3'>
        <div className='row gy-3'>
          <span className='text'>Was ist inklusive?</span>
          <div className='col-12 d-flex justify-content-between'>
            Stühle & Tische
            <div className="form-check form-switch">
              <input className="form-check-input" type="checkbox" name="furniture" role="switch" id="flexSwitchCheckDefault" onClick={handleSwitch} defaultChecked={data?.furniture} />
            </div>
          </div>
          <div className='col-12 d-flex justify-content-between'>
            Catering
            <div className="form-check form-switch">
              <input className="form-check-input" type="checkbox" name="catering" role="switch" id="flexSwitchCheckDefault" onClick={handleSwitch} defaultChecked={data?.catering} />
            </div>
          </div>
          <div className='col-12 d-flex justify-content-between'>
            Deko
            <div className="form-check form-switch">
              <input className="form-check-input" type="checkbox" name="decoration" role="switch" id="flexSwitchCheckDefault" onClick={handleSwitch} defaultChecked={data?.decoration} />
            </div>
          </div>
        </div>
        <div className='row mt-3'>
          <span className='subtext p-2'>Preis</span>
          <input type='number' placeholder='Preis' name='price' defaultValue={data?.price} onChange={handleTextEdit} />
        </div>
      </div>
    </div>
  )
}

export default Location