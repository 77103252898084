import React, { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import DetailNavBar from '../components/DetailNavBar'
import useAxiosPrivate from '../hooks/useAxiosPrivate'

function Foto() {
  const axiosPrivate = useAxiosPrivate()
  const queryClient = useQueryClient()

  const [timer, setTimer] = useState(null);
  const fetchData = async () => {
    const res = await axiosPrivate.get(`/photographer/get`);
    return res.data;
  };

  const { data } = useQuery({
    queryKey: ['photographer'],
    queryFn: () => fetchData(),
    keepPreviousData: true,
  });

  const mutationEdit = useMutation({
    mutationFn: (updatedItem) => {
      return axiosPrivate.put('/photographer/edit', updatedItem)
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(['photographer']);
      queryClient.invalidateQueries(['user']);
    },
  })

  const handleSwitch = (event) => {
    const input = { ...data, [event.target.name]: event.target.checked }
    mutationEdit.mutate(input)
  }

  const handleTextEdit = (event) => {
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(
      setTimeout(async () => {
        mutationEdit.mutate({ ...data, [event.target.name]: event.target.value })
      }, 500)
    );
  }
  return (
    <div className='container detail p-3'>

      <DetailNavBar backTo={"decoration"} goTo={"cake"} />

      <div className='mt-5 p-3'>
        {/* Partner */}
        <div className='detail-wrapper p-3'>
          <span className='subtext p-2'>Dienstleister</span>
          <div className='row p-3'>
            <input type='text' name='partner' placeholder='WillEyes Wedding' defaultValue={data?.partner} onChange={handleTextEdit} />
          </div>
          <div className='row my-2 px-3'>
            <div className='col-12 d-flex justify-content-between'>
              Fotograf
              <div className="form-check form-switch">
                <input className="form-check-input" name='photographer' type="checkbox" role="switch" id="flexSwitchCheckDefault" defaultChecked={data?.photographer} onClick={handleSwitch} />

              </div>
            </div>
          </div>
          <div className='row my-2 px-3'>
            <div className='col-12 d-flex justify-content-between'>
              Filmograf
              <div className="form-check form-switch">
                <input className="form-check-input" name='videographer' type="checkbox" role="switch" id="flexSwitchCheckDefault" defaultChecked={data?.videographer} onClick={handleSwitch} />
              </div>
            </div>
          </div>
          <div className='row mt-3 p-3'>
            <span className='subtext p-2'>Preis</span>
            <input type='number' name='price' placeholder='Preis' defaultValue={data?.price} onChange={handleTextEdit} />
          </div>
        </div>



      </div>
    </div>
  )
}

export default Foto