import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import DetailNavBar from '../components/DetailNavBar';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { formatDateInput } from '../util/DateHelper';

function Invitation() {
  const axiosPrivate = useAxiosPrivate()
  const queryClient = useQueryClient()

  const [timer, setTimer] = useState(null);
  const fetchData = async () => {
    const res = await axiosPrivate.get(`/invitation/get`);
    return res.data;
  };

  const { data, isLoading } = useQuery({
    queryKey: ['invitation'],
    queryFn: () => fetchData(),
    keepPreviousData: true,
  });

  const mutationEdit = useMutation({
    mutationFn: (updatedItem) => {
      return axiosPrivate.put('/invitation/edit', updatedItem)
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(['invitation']);
      queryClient.invalidateQueries(['user']);
    },
  })

  const handleSwitch = (event) => {
    const input = { ...data, [event.target.name]: event.target.checked }
    mutationEdit.mutate(input)
  }

  const handleTextEdit = (event) => {
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(
      setTimeout(async () => {
        mutationEdit.mutate({ ...data, [event.target.name]: event.target.value })
      }, 500)
    );
  }

  return (
    <div className='container detail p-3'>
      <DetailNavBar backTo={"cake"} goTo={"music"} />

      {/* Partner */}
      <div className='mt-5 p-2'>
        <span className='subtext p-2'>Partner Invitation</span>
        <div className='row mt-3'>
          <input type='text' name='partner' placeholder='Partner' defaultValue={data?.partner} onChange={handleTextEdit} />
        </div>
      </div>

      {/* Datum */}
      <div className='mt-5 detail-wrapper p-3'>
        <span className='subtext p-3'>Datum zum Versenden</span>
        <div className='row mt-3'>
          <div className='col-6'>
            <input type='date' name='date' defaultValue={!isLoading ? formatDateInput(data?.date) : ""} onChange={handleTextEdit} />
          </div>
        </div>

        <div className='row mt-3 p-3'>
          <span className='subtext p-2'>Preis</span>
          <input type='number' name='price' placeholder='Preis' defaultValue={data?.price} onChange={handleTextEdit} />
        </div>
      </div>


    </div>
  )
}

export default Invitation