import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import DetailNavBar from '../components/DetailNavBar';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

function Music() {
  const axiosPrivate = useAxiosPrivate()
  const queryClient = useQueryClient()

  const [timer, setTimer] = useState(null);
  const fetchData = async () => {
    const res = await axiosPrivate.get(`/music/get`);
    return res.data;
  };

  const { data } = useQuery({
    queryKey: ['music'],
    queryFn: () => fetchData(),
    keepPreviousData: true,
  });

  const mutationEdit = useMutation({
    mutationFn: (updatedItem) => {
      return axiosPrivate.put('/music/edit', updatedItem)
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(['music']);
      queryClient.invalidateQueries(['user']);
    },
  })

  const handleSwitch = (event) => {
    const input = { ...data, [event.target.name]: event.target.checked }
    mutationEdit.mutate(input)
  }

  const handleTextEdit = (event) => {
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(
      setTimeout(async () => {
        mutationEdit.mutate({ ...data, [event.target.name]: event.target.value })
      }, 500)
    );
  }

  return (
    <div className='container detail p-3'>
      <DetailNavBar backTo={"cake"} goTo={"dress"} />

      {/* Datum */}
      <div className='mt-5 detail-wrapper p-3'>
        <div className='row mt-3'>
          <div className='col-12 d-flex justify-content-between'>
            DJ
            <div className="form-check form-switch">
              <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" defaultChecked={data?.dj} name='dj' onClick={handleSwitch} />
            </div>
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-12 d-flex justify-content-between'>
            Sänger/in
            <div className="form-check form-switch">
              <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" defaultChecked={data?.singer} name='singer' onClick={handleSwitch} />
            </div>
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-12 d-flex justify-content-between'>
            Saxophon
            <div className="form-check form-switch">
              <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" defaultChecked={data?.saxophone} name='saxophone' onClick={handleSwitch} />
            </div>
          </div>
        </div>
      </div>



      {/* Datum */}
      <div className='mt-5 detail-wrapper p-3'>
        {/* Partner */}
        <div className='row p-2'>
          <span className='subtext p-2'>Partner DJ</span>
          <div className='row mt-3'>
            <input type='text' placeholder='WillEyes Wedding' name='partner' defaultValue={data?.partner} onChange={handleTextEdit} />
          </div>
        </div>
        <div className='row p-2 mt-1'>
          <span className='subtext p-2'>Zeitfenster</span>
          <div className='d-flex p-2 align-items-center'>
            <span className='me-2 subtext'>Von</span>
            <input type='time' name='timeFrom' defaultValue={data?.timeFrom} onChange={handleTextEdit} />
            <span className='mx-2 subtext'>Bis</span>
            <input type='time' name='timeUntil' defaultValue={data?.timeUntil} onChange={handleTextEdit} />
          </div>
        </div>
        <div className='row mt-3 p-3'>
          <span className='subtext p-2'>Preis</span>
          <input type='number' placeholder='Preis' name='price' defaultValue={data?.price} onChange={handleTextEdit} />
        </div>
      </div>
    </div>
  )
}

export default Music