import { jwtDecode } from "jwt-decode";
import { useRef, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

import axios from "../../api/axios";
const LOGIN_URL = "/auth/login";

const Login = () => {
  const { setAuth } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const errRef = useRef();
  const [errMsg, setErrMsg] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData(e.target);
      const user = Object.fromEntries(formData);
      const response = await axios.post(LOGIN_URL, user, {
        withCredentials: true,
      });

      const accessToken = response?.data?.token;

      const token = jwtDecode(accessToken)
      const role = token.role
      setAuth({ user, accessToken, role });
      navigate(from, { replace: true });
    } catch (err) {
      if (!err?.response) {
        setErrMsg('No Server Response');
      } else if (err.response?.status === 400) {
        setErrMsg('Missing Username or Password');
      } else if (err.response?.status === 401) {
        setErrMsg('Unauthorized');
      } else {
        setErrMsg('Login Failed');
      }
      console.log("error")
      errRef.current.focus();
    }
  };

  return (
    <>
      <div className="" style={{ backgroundColor: "#557059", height: "100vh" }}>
        <div className="pt-5 d-flex justify-content-center fw-bold fs-3">
          <p style={{ color: "#FEF5EC" }}>Willkommen bei WillEyes Wedding</p>
        </div>
        <div className="container mt-5">
          <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
          <form onSubmit={handleSubmit}>
            <div className="d-flex form-group mail">
              <input
                type="email"
                name="email"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="E-Mail Adresse"
              />
              <i className="p-3 bi bi-envelope-fill icon"></i>
            </div>
            <div className="d-flex form-group mt-3 password">
              <input
                type="password"
                name="password"
                className="form-control"
                id="exampleInputPassword1"
                placeholder="Passwort"
              />
              <i className="p-3 bi bi-key-fill icon"></i>
            </div>
            <div className="d-flex justify-content-between ps-3 mt-2">
              <a style={{ color: "#FEF5EC", textDecoration: "none" }} href="/register">Registrieren</a>
              <a style={{ color: "#FEF5EC", textDecoration: "none" }} href="/forgotPassword">Passwort vergessen?</a>
            </div>
            <div className="d-grid gap-2">
              <button className="btn btn-primary mt-3" style={{ backgroundColor: "#FEF5EC", color: "#557059", border: "1px solid #FEF5EC" }}>Einloggen</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
