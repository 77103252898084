import React, { useContext, useState } from 'react';

import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthContext from "../context/AuthProvider";
import useAuth from '../hooks/useAuth';
import useAxiosPrivate from "../hooks/useAxiosPrivate";

function DetailNavBar({ backTo, goTo }) {
  const { t, i18n } = useTranslation();
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [navExpanded, setNavExpanded] = useState(false)
  const location = useLocation()
  const { setAuth } = useContext(AuthContext);
  const axiosPrivate = useAxiosPrivate();

  const changeLanguage = () => {
    if (i18n.language === "en") {
      i18n.changeLanguage("de")
    } else {
      i18n.changeLanguage("en")
    }
  }

  const goBack = () => {
    navigate(`/${backTo}`)
  }

  const done = () => {
    if (goTo !== undefined) {
      navigate(`/${goTo}`)
    }
  }

  const logout = async () => {
    await axiosPrivate.get("/auth/logout");
    setAuth({});
    navigate('/login');
  }

  return (
    <div className='d-flex align-items-center justify-content-between'>
      {backTo !== undefined ?
        <div onClick={goBack}><i className="bi bi-arrow-left-circle fs-4" style={{ cursor: "pointer" }}></i></div>
        :
        <div></div>
      }
      <Dropdown>
        <Dropdown.Toggle className='fs-5' id="dropdown-basic" style={{ backgroundColor: "#5E7862", color: "#FBEDE0", border: "1px solid #687E6C" }}>
          {t(location.pathname.substring(1))}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={() => navigate("/guestlist")}>{t("guestlist")}</Dropdown.Item>
          <Dropdown.Item onClick={() => navigate("/location")}>{t("location")}</Dropdown.Item>
          <Dropdown.Item onClick={() => navigate("/catering")}>{t("catering")}</Dropdown.Item>
          <Dropdown.Item onClick={() => navigate("/decoration")}>{t("decoration")}</Dropdown.Item>
          <Dropdown.Item onClick={() => navigate("/foto")}>{t("foto")}</Dropdown.Item>
          <Dropdown.Item onClick={() => navigate("/cake")}>{t("cake")}</Dropdown.Item>
          <Dropdown.Item onClick={() => navigate("/invitation")}>{t("invitation")}</Dropdown.Item>
          <Dropdown.Item onClick={() => navigate("/music")}>{t("music")}</Dropdown.Item>
          <Dropdown.Item onClick={() => navigate("/dress")}>{t("dress")}</Dropdown.Item>
          <Dropdown.Item onClick={() => navigate("/moderator")}>{t("moderator")}</Dropdown.Item>
          <Dropdown.Item onClick={() => navigate("/car")}>{t("car")}</Dropdown.Item>
          <Dropdown.Item onClick={() => navigate("/accommodation")}>{t("accommodation")}</Dropdown.Item>
          <Dropdown.Item onClick={() => navigate("/seating")}>{t("seating")}</Dropdown.Item>
          <Dropdown.Item onClick={() => navigate("/schedule")}>{t("schedule")}</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <div className='d-flex align-items-center px-2 pt-1' onClick={done} style={{ cursor: "pointer", border: "1px solid #FDF4ED", borderRadius: "12px", fontSize: "1.3rem" }}>Done&nbsp;<i className="bi bi-check-circle"></i></div>
    </div>
  )
}

export default DetailNavBar