import React, { useState } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import background from "../img/0873 1.png";
import { formatDate } from '../util/DateHelper';

import { useQuery } from 'react-query';

function Overview() {

    const axiosPrivate = useAxiosPrivate()
    const [costs, setCosts] = useState(0)

    const fetchData = async () => {
        const res = await axiosPrivate.get(`/wedding/get`);
        return res.data;
    };

    const { data } = useQuery({
        queryKey: ['wedding'],
        queryFn: () => fetchData(),
        keepPreviousData: true,
    });

    const fetchUser = async () => {
        const res = await axiosPrivate.get(`/user/getById`);
        setCosts(res.data.catering.priceFood +
            res.data.catering.priceDrinks +
            res.data.catering.priceExtras +
            res.data.car.price +
            res.data.dress.price +
            res.data.moderator.price +
            res.data.music.price +
            res.data.photographer.price +
            res.data.location.price +
            res.data.decoration.price +
            res.data.invitation.price
        )
        return res.data;
    };

    useQuery({
        queryKey: ['user'],
        queryFn: () => fetchUser(),
        keepPreviousData: true,
    });

    return (
        <>
            <div className="header d-flex">
                <div className="py-2">
                    <div className="subtext">
                        Budget
                    </div>
                    <div>
                        {data?.budget}€
                    </div>
                    <div className="subtext">
                        Kosten
                    </div>
                    <div>
                        {costs}€
                    </div>
                    <div className="subtext">
                        Übrig
                    </div>
                    <div>
                        {data?.budget - costs}€
                    </div>
                </div>
                <div className="d-none d-md-block mt-2" style={{ backgroundImage: `url(${background})` }}>
                    <img src={background} alt="..." />
                </div>
                <div className="infobox px-3 py-2">
                    <div className="subtext">
                        Paar
                    </div>
                    <div>
                        {data?.partner1} & {data?.partner2}
                    </div>
                    <div className="subtext">
                        Hochzeit
                    </div>
                    <div>
                        {formatDate(data?.date)}
                    </div>
                    <div className="subtext">
                        Countdown
                    </div>
                    <div>
                        24 Tage
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="d-block d-md-none mt-2" style={{ backgroundImage: `url(${background})` }}>
                    <img src={background} alt="..." style={{ width: "100%" }} />
                </div>
            </div>
        </>
    )
}

export default Overview