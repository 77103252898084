import React, { useRef, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import Person from './Person'

function Group({ group }) {
    const axiosPrivate = useAxiosPrivate()
    const queryClient = useQueryClient()
    const editRef = useRef()
    const [edit, setEdit] = useState(false)

    const mutationPost = useMutation({
        mutationFn: (data) => {
            return axiosPrivate.post('/person/add', data)
        },
        onSuccess: (data, variables, context) => {
            // queryClient.invalidateQueries(['guestsPersons']);
            queryClient.invalidateQueries(['guestsGroups']);
        },
    })
    const mutationEdit = useMutation({
        mutationFn: (updatedItem) => {
            return axiosPrivate.put('/group/edit', updatedItem)
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(['guestsPersons']);
            queryClient.invalidateQueries(['guestsGroups']);
            setEdit(false)
        },
    })

    const mutationDelete = useMutation({
        mutationFn: (deleteItem) => {
            return axiosPrivate.delete('/group/delete', {
                data: deleteItem
            })
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(['guestsPersons']);
            queryClient.invalidateQueries(['guestsGroups']);
        },
    })

    const handleDelete = () => {
        mutationDelete.mutate({ ids: [group.id] })
    }

    const handleTextEdit = () => {
        mutationEdit.mutate({ ...group, name: editRef.current.value })
    }
    const handlePost = async (e) => {
        mutationPost.mutate({ groupId: group.id })
    }

    return (
        <div className='d-grid'>
            <div className='d-flex detail-wrapper p-2 mt-3 justify-content-between' style={{ borderBottomRightRadius: "0px", borderBottomLeftRadius: "0px" }}>
                {!edit ?
                    <div className='editRow w-100'>
                        <span className=''><i className="bi bi-people-fill"></i>&nbsp;{group?.name}</span>
                        <span className='edit' onClick={() => setEdit(true)}>&nbsp;<i className="bi bi-pencil"></i></span>
                        <span className='edit' onClick={handleDelete}>&nbsp;<i className="bi bi-trash"></i></span>
                    </div>
                    :
                    <div><input type='text' ref={editRef} name='name' defaultValue={group?.name} />
                        <span onClick={handleTextEdit} style={{ cursor: "pointer" }}>&nbsp;<i className="bi bi-floppy-fill"></i></span>
                        <span onClick={() => setEdit(false)} style={{ cursor: "pointer" }}>&nbsp;<i className="bi bi-x"></i></span>
                    </div>
                }
                <span onClick={handlePost} style={{ cursor: "pointer" }}><i className="bi bi-person-plus-fill pe-2"></i></span>
            </div>
            <div className='d-grid detail-wrapper p-2' style={{ borderTopRightRadius: "0px", borderTopLeftRadius: "0px" }}>
                {group?.persons.map(person => (
                    <Person key={person.id} person={person} inGroup={true} />
                ))}
            </div>
        </div>
    )
}

export default Group