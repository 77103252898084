import React from 'react'

function Planning({ data }) {
    return (
        <div className='container-fluid d-grid gap-2 mt-2'>
            <div className='row gx-2' style={{ marginLeft: "-1rem", marginRight: "-1rem" }}>
                <div className=''><div className="infobox py-2 px-2">
                    Ort
                    <div>
                        <span className='subtext'>Getting Ready: </span>{data?.location?.gettingReady}
                    </div>
                    <div>
                        <span className='subtext'>Zeremonie:</span> {data?.location?.ceremony}
                    </div>
                    <div>
                        <span className='subtext'>Party: </span>{data?.location?.party}
                    </div>
                    <div>
                        <span className='subtext'>Preis:</span> {data?.location?.price}€
                    </div>
                </div></div>
            </div>
            <div className='row gx-2' style={{ marginLeft: "-1rem", marginRight: "-1rem" }}>
                <div className='col-6'>
                    <div className="infobox py-2 px-2">
                        Fotograf
                        <div>
                            <span className='subtext'>Partner:</span> {data?.photographer?.partner}
                        </div>
                        <div>
                            <span className='subtext'>Preis:</span> {data?.photographer?.price}€
                        </div>
                    </div>

                </div>
                <div className='col-6'>
                    <div className="infobox py-2 px-2">
                        Einladungen
                        <div>
                            <span className='subtext'>Partner:</span> {data?.invitation?.partner}
                        </div>
                        <div>
                            <span className='subtext'>Preis:</span> {data?.invitation?.price}€
                        </div>
                    </div>

                </div>

            </div>
            <div className='row gx-2' style={{ marginLeft: "-1rem", marginRight: "-1rem" }}>
                <div className='col-6'>
                    <div className="infobox py-2 px-2">
                        Catering
                        <div>
                            <span className='subtext'>Partner:</span> {data?.catering?.partner}
                        </div>
                        <div>
                            <span className='subtext'>Preis:</span> {data?.catering?.priceFood + data?.catering?.priceDrinks + data?.catering?.priceExtras}€
                        </div>
                    </div>
                </div>
                <div className='col-6'>
                    {/* <div className='row'> */}
                    <div className='infobox py-2 px-2'>
                        Dekoration
                        <div>
                            <span className='subtext'>Partner:</span> {data?.decoration?.partner}
                        </div>
                        <div>
                            <span className='subtext'>Preis:</span> {data?.decoration?.price}€
                        </div>
                    </div>
                    {/* </div> */}
                </div>
            </div>
            <div className='row gx-2' style={{ marginLeft: "-1rem", marginRight: "-1rem" }}>
                <div className=''><div className="infobox py-2 px-2">
                    Gästeliste
                    <div>
                        <span className='subtext'>Anzahl: </span>{data?.people?.length}
                    </div>
                    <div>
                        <span className='subtext'>Zugesagt:</span> {data?.people?.filter(people => people.status === "accepted").length}
                    </div>
                    <div>
                        <span className='subtext'>Ausstehend: </span>{data?.people?.filter(people => people.status === "pending").length}
                    </div>
                    <div>
                        <span className='subtext'>Abgesagt:</span> {data?.people?.filter(people => people.status === "declined").length}
                    </div>
                </div></div>
            </div>
            <div className='row gx-2' style={{ marginLeft: "-1rem", marginRight: "-1rem" }}>
                <div className="col-6">
                    <div className="py-2 px-2 infobox">
                        DJ/Musik
                        <div>
                            <span className='subtext'>Partner:</span> {data?.music?.partner}
                        </div>
                        <div>
                            <span className='subtext'>Preis:</span> {data?.music?.price}€
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="py-2 px-2 infobox">
                        Moderator
                        <div>
                            <span className='subtext'>Partner:</span> {data?.moderator?.partner}
                        </div>
                        <div>
                            <span className='subtext'>Preis:</span> {data?.moderator?.price}€
                        </div>
                    </div>
                </div>
            </div>
            <div className='row gx-2' style={{ marginLeft: "-1rem", marginRight: "-1rem" }}>
                <div className="col-6">
                    <div className="py-2 px-2 infobox">
                        Kleidung
                        <div>
                            <span className='subtext'>Partner:</span> {data?.dress?.partner}
                        </div>
                        <div>
                            <span className='subtext'>Preis:</span> {data?.dress?.price}€
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="py-2 px-2 infobox">
                        Kuchen
                        <div>
                            <span className='subtext'>Partner:</span> {data?.cakes?.length}
                        </div>
                        <div>
                            <span className='subtext'>Anzahl:</span> {data?.cakes?.length}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Planning