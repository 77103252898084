import React, { useContext, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import AuthContext from "../context/AuthProvider";
import useAuth from '../hooks/useAuth';
import useAxiosPrivate from "../hooks/useAxiosPrivate";

function NavbarTop() {
  const { t, i18n } = useTranslation();
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [navExpanded, setNavExpanded] = useState(false)
  const queryClient = useQueryClient()

  const { setAuth } = useContext(AuthContext);
  const axiosPrivate = useAxiosPrivate();

  const changeLanguage = () => {
    if (i18n.language === "en") {
      i18n.changeLanguage("de")
    } else {
      i18n.changeLanguage("en")
    }
  }

  const logout = async () => {
    await axiosPrivate.get("/auth/logout");
    queryClient.removeQueries()
    setAuth({});
    navigate('/login', { replace: true });
  }


  return (
    <Navbar expand="lg" className="" style={{ backgroundColor: "#637E67" }} expanded={navExpanded} onToggle={() => setNavExpanded(!navExpanded)}>
      <Container>

        <Navbar.Brand><Link className="navbar-brand" to="/" style={{ color: "#F1F8E8" }}><img src='weplanner 1.png' alt='...' /></Link></Navbar.Brand>

        <Navbar.Toggle className='custom-toggler' aria-controls="basic-navbar-nav" style={{ borderRadius: "20px", backgroundColor: "#5B785F", borderColor: "#5B785F" }} />

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to={"/"} onClick={() => setNavExpanded(false)}>Übersicht</Nav.Link>
            <Nav.Link as={Link} to={"/guestlist"} onClick={() => setNavExpanded(false)}>Gästeliste</Nav.Link>
            <Nav.Link as={Link} to={"/location"} onClick={() => setNavExpanded(false)}>Location</Nav.Link>
            <Nav.Link as={Link} to={"/catering"} onClick={() => setNavExpanded(false)}>Catering</Nav.Link>
            <Nav.Link as={Link} to={"/decoration"} onClick={() => setNavExpanded(false)}>Dekoration</Nav.Link>
            <Nav.Link as={Link} to={"/foto"} onClick={() => setNavExpanded(false)}>Foto&nbsp;&&nbsp;Film</Nav.Link>
            <Nav.Link as={Link} to={"/cake"} onClick={() => setNavExpanded(false)}>Kuchen</Nav.Link>
            <Nav.Link as={Link} to={"/invitation"} onClick={() => setNavExpanded(false)}>Einladungen</Nav.Link>
            <Nav.Link as={Link} to={"/music"} onClick={() => setNavExpanded(false)}>Musik</Nav.Link>
            <Nav.Link as={Link} to={"/dress"} onClick={() => setNavExpanded(false)}>Kleider</Nav.Link>
            <Nav.Link as={Link} to={"/moderator"} onClick={() => setNavExpanded(false)}>Moderator</Nav.Link>
            <Nav.Link as={Link} to={"/car"} onClick={() => setNavExpanded(false)}>Auto</Nav.Link>
            <Nav.Link as={Link} to={"/accommodation"} onClick={() => setNavExpanded(false)}>Unterkunft</Nav.Link>
            <Nav.Link as={Link} to={"/seating"} onClick={() => setNavExpanded(false)}>Sitzplan</Nav.Link>
            <Nav.Link as={Link} to={"/schedule"} onClick={() => setNavExpanded(false)}>Ablaufplan</Nav.Link>
            {auth?.role === "admin" ?
              <Nav.Link as={Link} to={"/admin"}>Admin</Nav.Link> :
              <></>
            }
            <Nav.Link as={Link} onClick={logout}>Ausloggen</Nav.Link>

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default NavbarTop