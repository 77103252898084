import React from 'react'
import Modal from 'react-modal'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import DetailNavBar from '../components/DetailNavBar'
import TablePerson from '../components/Seating/Person'
import Table from '../components/Seating/Table'
import useAxiosPrivate from '../hooks/useAxiosPrivate'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    padding: "2rem",
    transform: 'translate(-50%, -50%)',
    backgroundColor: "#557059",
    color: "#FEF5EC"
  },
};

function Tables() {
  const axiosPrivate = useAxiosPrivate()
  const queryClient = useQueryClient()
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [selectedTable, setSelectedTable] = React.useState(0)
  const fetchPersons = async () => {
    const res = await axiosPrivate.get(`/person/getWithoutTable`);
    return res.data;
  };

  function openModal(table) {
    setIsOpen(true);
    setSelectedTable(table)
  }

  function closeModal() {
    setIsOpen(false);

  }

  const { data: personData } = useQuery({
    queryKey: ['seatingPersons'],
    queryFn: () => fetchPersons(),
    keepPreviousData: true,
  });
  const fetchTables = async () => {
    const res = await axiosPrivate.get(`/table/get`);
    return res.data;
  };

  const { data: tableData } = useQuery({
    queryKey: ['seatingTables'],
    queryFn: () => fetchTables(),
    keepPreviousData: true,
  });


  const mutationPostTable = useMutation({
    mutationFn: (data) => {
      return axiosPrivate.post('/table/create', data)
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(['seatingTables']);
    },
  })

  const handlePostTable = async (e) => {
    mutationPostTable.mutate({})
  }

  return (
    <div className='container detail p-3'>
      <DetailNavBar backTo={"accommodation"} goTo={"schedule"} />

      {tableData?.map(table => (
        <Table key={table.id} table={table} openModal={openModal} />
      ))}

      <div className='d-flex justify-content-between mt-5'>
        <div className='detail-wrapper px-3 py-1' onClick={handlePostTable} style={{ cursor: "pointer" }}><i className="bi bi-person-add"></i> + Neuer Tisch</div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h1>Personen hinzufügen</h1>
        <h4>Tisch: {selectedTable.name}</h4>
        {personData?.map(person => <TablePerson key={person.id} person={person} tableId={selectedTable.id} add={true} />)}
      </Modal>
    </div>
  )
}

export default Tables