import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import axios from "../../api/axios";

const SetNewPassword = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(true);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [passwordError, setPasswordError] = React.useState("")
  const [invalidCode, setInvalidCode] = React.useState(false)
  const changeLanguage = (language) => {
    i18n.changeLanguage(language)
  }

  const query = new URLSearchParams(window.location.search)
  const token = query.get("token")

  const { state } = useLocation();

  React.useEffect(() => {
    const validateResetCode = async () => {
      const response = await axios.post("/activation/validateCode", {
        passwordResetCode: token,
      });

      if (response.status === 200) {
        setIsSuccess(true);
      } else {
        setInvalidCode(true)
      }

      setIsLoading(false);
    };

    validateResetCode().catch((err) => console.log(err));
  }, [token]);


  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData(e.target);
      formData.append("passwordResetCode", token)
      const input = Object.fromEntries(formData);

      if (input.newPassword === input.newPasswordConfirm) {
        const response = await axios.post("/activation/resetPassword", input);
        if (response.status === 200) {
          navigate("/login")
        }
      } else {
        setPasswordError("Passwords do not match")
      }

    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {isSuccess ?
        <div className="pt-5" style={{ backgroundColor: "#557059", height: "100vh" }}>
          <div className="d-flex justify-content-center fw-bold fs-3">
            <p style={{ color: "#FEF5EC" }}>Neues Passwort eingeben</p>
          </div>
          <div className="container mt-5">
            <form onSubmit={handleSubmit}>
              <div className="d-flex mb-2 form-group mail">
                <input
                  type="password"
                  name="newPassword"
                  className="form-control"
                  id="password"
                  autocomplete="new-password"
                  aria-describedby="passwordHelp"
                  placeholder="Neues Passwort"
                />
                <i className="p-3 bi bi-key-fill icon" style={{ color: "#FEF5EC" }}></i>
              </div>
              <div className="d-flex mb-2 form-group mail">

                <input
                  type="password"
                  name="newPasswordConfirm"
                  className="form-control"
                  id="password"
                  autocomplete="new-password"
                  aria-describedby="passwordHelp"
                  placeholder="Passwort erneut eingeben"
                />
                <i className="p-3 bi bi-key-fill icon" style={{ color: "#FEF5EC" }}></i>
              </div>
              <span style={{ color: "red" }}>{passwordError}</span>
              <div className="d-grid gap-2">
                <button className="btn btn-secondary mt-1" style={{ backgroundColor: "#FEF5EC", color: "#557059", border: "1px solid #FEF5EC" }}>
                  Bestätigen
                </button>
              </div>
            </form>

            <div className="d-grid gap-2">
              <button
                className="btn btn-secondary mt-2"
                style={{ backgroundColor: "#FEF5EC", color: "#557059", border: "1px solid #FEF5EC" }}
                onClick={() => navigate("/login")}
              >
                Zurück zum Login
              </button>
            </div>
          </div>
        </div>
        : <div className="position-absolute top-50 start-50 translate-middle">
          {invalidCode ? <h1>{t("invalid_code")}</h1> : <></>}
        </div>
      }</>
  );
};

export default SetNewPassword;
